<template>
<div id="orderDetail">
  <div class="bg-white searchDom">
    <div class="flex align-center justify-start">
      <i @click="goBack" class="el-icon-arrow-left" style="font-weight: 700;font-size: 16px;cursor: pointer;"></i>
      订单详情
    </div>
  </div>
  <div class="searchList detail-container">
    <!-- <el-card class="box-card">
      <div>
        <el-steps :active="formatStepStatus(order.order_state)"  finish-status="success" align-center>
        <el-step title="提交订单"></el-step>
        <el-step title="待打印"></el-step>
        <el-step title="打印中"></el-step>
        <el-step title="待发货"></el-step>
        <el-step title="已发货"></el-step>
        <el-step title="已取消"></el-step>
        <el-step title="已完成"></el-step>
        </el-steps>
      </div>
    </el-card> -->
    <el-card shadow="never" style="margin-top: 15px">
      <div class="operate-container" style="text-align:left">
        <i class="el-icon-warning color-danger" style="margin-left: 20px"></i>
        <span class="color-danger">当前订单状态：{{order | orderState}}</span>
      </div>
      <div style="margin-top: 20px;text-align:left">
        <span class="font-small">订单信息</span>
      </div>
      <div class="table-layout">
        <el-row>
          <el-col :span="4" class="table-cell-title">订单标题</el-col>
          <el-col :span="4" class="table-cell-title">订单编号</el-col>
          <el-col :span="4" class="table-cell-title">订单来源</el-col>
          <el-col :span="4" class="table-cell-title">订单支付时间</el-col>
          <el-col :span="4" class="table-cell-title">订单打印类型</el-col>
          <el-col :span="4" class="table-cell-title">配送方式</el-col>
        </el-row>
        <el-row>
          <el-col :span="4" class="table-cell">{{order.order_wutu_title}}</el-col>
          <el-col :span="4" class="table-cell">{{order.order_number}}</el-col>
          <el-col :span="4" class="table-cell">{{order.order_wutu_source}}</el-col>
          <el-col :span="4" class="table-cell">{{order.order_pay_time*1000 | getDate}}</el-col>
          <el-col :span="4" class="table-cell">{{order | orderCategory}}</el-col>
          <el-col :span="4" class="table-cell">{{order.order_express_company}}</el-col>
        </el-row>
      </div>
      <div style="margin-top: 20px;text-align:left">
        <span class="font-small">收货人信息</span>
      </div>
      <div class="table-layout">
        <el-row>
          <el-col :span="8" class="table-cell-title">收货人</el-col>
          <el-col :span="8" class="table-cell-title">手机号码</el-col>
          <el-col :span="8" class="table-cell-title">收货地址</el-col>
        </el-row>
        <el-row>
          <el-col :span="8" class="table-cell">{{order.order_addr_name}}</el-col>
          <el-col :span="8" class="table-cell">{{order.order_addr_phone}}</el-col>
          <el-col :span="8" class="table-cell">{{order.order_addr_detail}}</el-col>
        </el-row>
      </div>
    </el-card>
    <!-- 订单列表 -->
    <el-card>
      <div style="">
        <span class="font-small">订单信息</span>
      </div>
    </el-card>
    <!--搜索列表-->
  <div class="searchList" style="margin-top:0;padding:0">
    <ul class="service-list row align-center margin-bottom-26 flex-start" 
      v-if="order.order_category=='1'">
      <li class="service-list-item margin-bottom-16"
        v-for="(v,i) in orderFileList.print" :key="i" style="width:33%;margin-right:0">
          <div class="service-content">
              <ul class="service-content-list" >
                  <li class="flex">
                      <h4>文件标题：</h4>
                      <p>{{v.print_1_filename}}</p>
                  </li>
                  <li class="flex">
                      <h4>打印数量：</h4>
                      <p>{{v.print_1_config_count}}</p>
                  </li>
                  <li class="flex">
                      <h4>文件格式：</h4>
                      <p>{{v.file_type | edit_imgtype}}</p>
                  </li>
                  <li class="flex">
                      <h4>打印风格：</h4>
                      <p>{{v.print_1_config_style | config_style}}</p>
                  </li>
                  <li class="flex">
                      <h4>打印面数：</h4>
                      <p>{{v.print_1_config_side | config_side}}</p>
                  </li>
                  <li class="flex">
                     <h4>打印尺寸：</h4>
                     <p>{{v.print_1_config_size | config_size_1}}</p>
                  </li>
                  <li class="flex">
                     <h4>打印材料：</h4>
                     <p>{{v.print_1_config_body | config_body}}</p>
                  </li>
                  <li class="flex">
                     <h4>装订方式：</h4>
                     <p>{{v.print_1_config_bind_mode | config_bind_mode}}</p>
                  </li>
                  <li class="flex">
                     <h4>文件总页数：</h4>
                     <p>{{v.file_page}}</p>
                  </li>
                  <li class="flex">
                    <h4>打印范围：</h4>
                    <p>{{v.print_1_config_page_begin}} - {{v.print_1_config_page_end}}</p>
                  </li>
                  <li class="flex">
                    <h4>每版打印页数：</h4>
                    <p>{{v.print_1_config_page_resize}}</p>
                  </li>
                  <li class="flex">
                    <h4>封面材料</h4>
                    <p>{{v.print_1_config_bind_body | config_bind_body}}</p>
                  </li>
                  <li class="flex">
                    <h4>封面印字内容</h4>
                    <p>{{v.print_1_config_bind_text}}</p>
                  </li>
                  <li class="flex">
                    <h4>封面工艺</h4>
                    <p>{{v.print_1_config_bind_craft | config_bind_craft}}</p>
                  </li>
                  <li class="flex">
                    <h4>封面颜色</h4>
                    <div class="flex flex-direction align-center">
								      <span v-if="v.print_1_config_bind_color=='1'" style="width: 20px;height: 20px;background-color: #6eb8dd;display: inline-block;"></span>
								      <span v-if="v.print_1_config_bind_color=='2'"	style="width: 20px;height: 20px;background-color: pink;display: inline-block;"></span>
								      <span v-if="v.print_1_config_bind_color=='3'"	style="width: 20px;height: 20px;background-color: greenyellow;display: inline-block;"></span>
								      <span v-if="v.print_1_config_bind_color=='4'"	style="width: 20px;height: 20px;background-color: orange;display: inline-block;"></span>
								      <span v-if="v.print_1_config_bind_color=='5'"	style="width: 20px;height: 20px;background-color: #bbb;display: inline-block;"></span>
								      <span v-if="v.print_1_config_bind_color=='6'"	style="width: 20px;height: 20px;background-color: yellow;display: inline-block;"></span>
								      <span v-if="v.print_1_config_bind_color=='7'"	style="width: 20px;height: 20px;background: url('https://bbo.cc/blue.png');display: inline-block;"></span>
								      <span v-if="v.print_1_config_bind_color=='8'" style="width: 20px;height: 20px;background-color: deeppink;display: inline-block;"></span>
							      </div>
                  </li>
                  <li class="flex">
                    <div class="p1">
                      <h4 class="flex justify-between">备注信息
                        <p>{{v.print_1_config_remark}}</p>
                        <el-button v-if="v.print_1_config_remark!='0'" @click="readInfo(v.print_1_config_remark)" type="text" size="medium" style="color: #4E9F5B !important;margin-top:-7px;margin-left:10px">查看详情</el-button>
                      </h4>
                    </div>
                  </li>
              </ul>
              <div class="ope flex align-center justify-center">
                <el-button v-if="order.order_state!='2'" @click="updownFile(v.file_url)" type="text" size="small" style="color:#4d9859 !important">下载文件</el-button>
						    <el-button v-if="order.order_state=='2'" @click="updownFile(v.file_remove_bg)" type="text" size="small" style="color:#4d9859 !important">下载文件</el-button>
              </div>
        </div>
      </li>
    </ul>
    <ul class="service-list row align-center margin-bottom-26 flex-start" 
      v-if="order.order_category=='2'">
      <li class="service-list-item margin-bottom-16"
        v-for="(v,i) in orderFileList.print" :key="i" style="width:20%;margin-right:0">
          <div class="service-content">
              <ul class="service-content-list" >
                  <li class="flex">
                      <h4>证件照：</h4>
                      <p><img :src="v.file_url" style="width: 30px;height: 40px;"/></p>
                  </li>
                  <li class="flex">
                      <h4>底色：</h4>
                      <p><span v-if="v.print_2_config_color=='1'" class="shadow"
								        style="display: inline-block;width: 20px;height: 20px;background-color: blue;"></span>
							          <span v-if="v.print_2_config_color=='2'" class="shadow"
							          	style="display: inline-block;width: 20px;height: 20px;background-color: white;"></span>
							          <span v-if="v.print_2_config_color=='3'" class="shadow"
								        style="display: inline-block;width: 20px;height: 20px;background-color: red;"></span>
                      </p>
                  </li>
                  <li class="flex">
                      <h4>尺寸：</h4>
                      <p>{{v.print_2_config_size | config_size_2}}</p>
                  </li>
                  <li class="flex">
                      <h4>板数：</h4>
                      <p>{{v.print_2_config_count}}</p>
                  </li>
              </ul>
              <div class="ope flex align-center justify-center" style="margin-top:-10px">
                <el-button v-if="order.order_state!='2'" @click="updownFile(v.file_url)" type="text" size="small" style="color:#4d9859 !important">下载</el-button>
						    <el-button v-if="order.order_state=='2'" @click="updownFile(v.file_remove_bg)" type="text" size="small" style="color:#4d9859 !important">下载</el-button>
              </div>
        </div>
      </li>
    </ul>
    <ul class="service-list row align-center margin-bottom-26 flex-start" 
      v-if="order.order_category=='3'">
      <li class="service-list-item margin-bottom-16"
        v-for="(v,i) in orderFileList.print" :key="i" style="width:20%;margin-right:0">
          <div class="service-content">
              <ul class="service-content-list" >
                  <li class="flex">
                      <h4>照片冲洗：</h4>
                      <p><img :src="v.file_url" style="width: 30px;height: 40px;"/></p>
                  </li>
                  <li class="flex">
                      <h4>相纸：</h4>
                      <p>{{v.print_3_config_paper | config_paper}}</p>
                  </li>
                  <li class="flex">
                      <h4>工艺：</h4>
                      <p v-if="v.print_3_config_technology=='0'">不塑胶</p>
                      <p v-if="v.print_3_config_technology=='1'">塑胶</p>
                  </li>
                  <li class="flex">
                      <h4>尺寸：</h4>
                      <p>{{v.print_3_config_size | config_size_3}}</p>
                  </li>
              </ul>
              <!-- <a href="javascript:;" target="_blank" class="service-list-more">了解更多</a> -->
              <div class="ope flex align-center justify-center" style="margin-top:-10px">
                <el-button v-if="order.order_state!='2'" @click="updownFile(v.file_url)" type="text" size="small" style="color:#4d9859 !important">下载</el-button>
						    <el-button v-if="order.order_state=='2'" @click="updownFile(v.file_remove_bg)" type="text" size="small" style="color:#4d9859 !important">下载</el-button>
              </div>
        </div>
      </li>
    </ul>
  </div>
  <!--分页-->
  <!-- <Pagination
      v-show="rows>1"
      :total="rows"
      @change = "getOrderManageDetail"
      :page.sync= "orderFileList.page"
      :limit.sync="orderFileList.limit"/> -->
</div>
  <!-- 分页 -->
  <div class="pageNum flex align-center justify-center" id="pageNum">
    <!-- <a @click="downloadFile" class="btn btn2" style="color:#fff !important;position: relative">下载文件</a> -->
    <a v-if="
      order.order_state != '0' &&
      order.order_state != '1' &&
      order.order_state != '5' &&
      Number(order.order_income_time) <= 0 " 
      @click="refund(order.order_id,order.order_pay_money)" class="btn btn2" style="color:#fff !important">售后退款</a>
  </div>
  <!-- 查看备注信息 -->
  <el-dialog id="infoModelBox" title="查看备注" :visible.sync="showInfoModel" :close-on-press-escape='false' :close-on-click-modal='false'
    append-to-body :before-close="closeInfoModel" style="width: 640px;min-height: 320px;border-radius: 8px;padding: 24px !important;margin:0 auto">
      <div  class="solid padding-16 text-14 text-333 margin-top-24 radius-4 text-left">
        <div v-html="content"></div>
      </div>
  </el-dialog>
  <!-- 退款 -->
  <el-dialog id="refund" :visible.sync="refundShow">
			<i @click.stop="closeMon('refundForm')" class="el-icon-close" style="
          font-size: 20px;
          position: absolute;
          right: 20px;
          top: 32px;
          color: #c0bebe;
          cursor: pointer;
        "></i>
			<div id="from">
				<el-form :model="refundForm" :rules="refundRules" ref="refundForm" label-position="top"
					label-width="100px" class="demo-ruleForm text-left">
					<el-form-item label="退款理由" prop="order5_reason">
						<el-input type="textarea" v-model="refundForm.order5_reason"></el-input>
					</el-form-item>
					<el-form-item label="附件" prop="file_ids">
						<el-upload :action="uploadUrl()" list-type="picture-card" ref="frontImg" :headers="fileheaders"
							:auto-upload="false" :with-credentials="false" :data="upfileData"
							:before-upload="beforUpfile" :on-change="upfileImg" :on-success="uploadFiles">
							<i class="el-icon-plus"></i>
						</el-upload>
						<el-dialog append-to-body :visible.sync="refundImg">
							<img width="100%" :src="dialogImageUrl" alt="" />
						</el-dialog>
					</el-form-item>
					<el-form-item label="退款金额" prop="order5_money">
						<el-input v-model="refundForm.order5_money"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click.stop="submitRefund('refundForm')">提交</el-button>
			</div>
		</el-dialog>
</div>    
</template>
<script>
import {orderManageExpress,orderManagePrint,
orderManageRemark,orderManageRemarkAdd,
orderManageRefundAsk,orderManageSetState,orderManageDetail,orderDownloadFile,fileSave,fileFind} from '@/api/http'
import Pagination from '@/components/pagination'
import SparkMD5 from 'spark-md5'
export default {
  components:{
    Pagination
  },
  data(){
    var checkMoney = (rule, value, callback) => {
				if (!value) {
					return callback(new Error("退款金额不能为空"));
				}
				setTimeout(() => {
					if (value < 0) {
						callback(new Error("不能为负数"));
					} else if (value > this.nowMoney) {
						callback(new Error("不能超过实付金额"));
					} else {
						callback();
					}
				}, 500);
			};
    return{
      showInfoModel:false,
      id:null,
      order:{},
      dataList:[],
      fileType:'',
      //获取订单文件列表
			orderFileList: {
        order_id:'',
				page: 1,
				limit: 5,
			},
      /* 分页参数*/
      pages:1, //总页数
      rows:1, //总条数
      content:'',
      refundShow: false,
				refundForm: {
					order_express_company_real: ''
				},
				refundRules: {
					file_ids: [{
						required: true,
						message: "请上传附件",
						trigger: "blur",
					}, ],
					order5_money: [{
						required: true,
						trigger: "blur",
						validator: checkMoney,
					}, ],
					order5_reason: [{
						required: true,
						message: "请输入退款理由",
						trigger: "blur",
					}, ],
				},
        fileList_: [], //保存上传文件的唯一uid
				filesArr: [], //临时存储文件id
				dialogImageUrl: "",
				refundImg: false,

				//上传图片额为参数
				upfileData: {
					key: "",
				},
				fileheaders: {
					// 'Content-Type':'application/x-www-form-urlencoded'
				},
				fileMd5: "", //文件md5
    }
  },
  filters:{
    checkMoney(rule, value, callback) {
				if (!value) {
					return callback(new Error("退款金额不能为空"));
				}
				setTimeout(() => {
					if (value < 0) {
						callback(new Error("不能为负数"));
					} else if (value > this.nowMoney) {
						callback(new Error("不能超过实付金额"));
					} else {
						callback();
					}
				}, 500);
			},
    edit_imgtype(val) {
			let arr = ['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'image/gif', 'application/pdf',
				'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
				'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'application/vnd.ms-powerpoint',
				'application/vnd.openxmlformats-officedocument.presentationml.presentation',
			]
			let arr1 = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx']
			let str
			arr.forEach((item, index) => {
				if (item == val) {
					str = arr1[index]
				}
			})
			return str
		},
		config_style(val) {
			let arr = ['', '黑白', '彩色喷墨', '彩色激光']
			return arr[val] || '/'
		},
		config_side(val) {
			let arr = ['', '单面', '双面']
			return arr[val] || '/'
		},
		config_size_1(val) {
			let arr = ['', 'A4', 'A3', 'B5']
			return arr[val] || '/'
		},
		config_body(val) {
			let arr = ['', '复印纸70g', '复印纸80g', '铜版纸128g', '铜版纸157g', '铜版纸200g', '铜版纸250g', '铜版纸300g']
			return arr[val] || '/'
		},
		config_bind_mode(val) {
			let arr = ['', '散装', '订书机装订', '压线', '圈装', '线状', '精装', '硬壳精装', '夹条装订', '骑马钉', '胶装']
			return arr[val] || '/'
		},
		config_bind_craft(val) {
			let arr = ['不腹膜', '哑膜', '亮膜']
			return arr[val]
		},
	  config_bind_body(val) {
	  	let arr = ['', '平面皮纹纸', '凹凸皮纹纸', '铜版纸']
	  	return arr[val] || '/'
	  },
	  config_bind_color(val) {
	  	let arr = ['', 'CC8400', 'FB5868', 'CF6966', 'FA8750', 'CB7340', 'DC3026', 'BB9039', 'CA1502']
	  	return arr[val] || '/'
	  },
		config_size_2(val) {
			let arr = ['', '一寸', '大一寸', '小二寸', '二寸', '大二寸']
			return arr[val] || '/'
		},
		config_paper(val) {
			let arr = ['', '乐凯圣铂人像光面', '乐凯圣铂人像绒面', '乐凯金圣莱光面', '乐凯金圣莱绒面', '富士金冠光面', '富士金冠绒面', '柯达皇家光面', '柯达皇家绒面']
			return arr[val] || '/'
		},
		config_size_3(val) {
			let arr = ['', '三寸照片', '四寸照片', '五寸照片', '大五寸照片', '六寸照片', '大六寸照片', '七寸照片']
			return arr[val] || '/'
		},
    orderState(val) {
			let arr = [
				"待支付",
				"待打印",
				"打印中",
				"待发货",
				"已发货",
				"已取消",
				"已完成",
			];
			let state = arr[Number(val.order_state)];
			return state;
		},
    orderCategory(val) {
        console.log('val:',val)
				let arr = ["", "文件和图片打印", "证件照打印", "照片冲洗"];
				let category = arr[Number(val.order_category)];
				return category;
		},
  },
  created(){
    this.id = this.$route.params.id;
    console.log(this.id)
    this.getOrderManageDetail()
    // orderManageDetail({order_id:this.id}).then(res=>{
    //   console.log('订单详情信息:',res.data)
    //   if(res.data.code == '1'){
    //     this.order = this.orderFileList = res.data.data
    //   }
    // })
    // this.getOrderManagePrint()
    
  },
  methods:{
      submitRefund(formName){
        let that = this
        this.refundForm.file_ids = this.filesArr.toString()
        this.$refs[formName].validate((valid)=>{
          if(valid){
            console.log(this.refundForm)
            orderManageRefundAsk(this.refundForm).then(res=>{
              console.log('退款申请',res.data)
            })
          }else {
						console.log("error submit!!");
						return false;
					}
        })
      },
    /* 退款*/
			refund(id, money) {
				this.refundShow = true;
				this.refundForm.order_id = id;
				this.nowMoney = money;
				console.log(this.refundForm);
			},
			/* 关闭退款 */
			closeMon(formName) {
				this.refundShow = false;
				this.$refs[formName].resetFields();
			},
			/* 上传文件前*/
			beforUpfile(file) {
				console.log("上传前", file);
				let date = this.util.formatDate(new Date());
				let date_ = this.util.formatTime(new Date()).replace(/,/g, "");
				let randnum = (Math.random() * 9999999999).toFixed(0);
				let fileName = date_ + randnum;
				this.upfileData.key = "upload/xcx/" + date + "/" + fileName;
			},
			uploadUrl() {
				return "https://oss-campus-print.yihuichuang.com/";
			},

			/* 计算文件md5*/
			upfileImg(file) {
				this.fileList_.push(file.uid);
				const _this = this;
				var fileReader = new FileReader();
				//此处打印file可看到file下的raw为文件属性
				var dataFile = file.raw;
				let blobSlice =
					File.prototype.slice ||
					File.prototype.mozSlice ||
					File.prototype.webkitSlice;
				var spark = new SparkMD5.ArrayBuffer();
				//获取文件二进制数据
				fileReader.readAsArrayBuffer(dataFile);
				//异步执行函数
				fileReader.onload = function(e) {
					spark.append(e.target.result);
					var md5 = spark.end();
					console.log("MD5", md5);
					_this.fileMd5 = md5;
					/* 查找文件是否存在*/
					_this.find_file(md5);
				};
			},

			/* 文件上传*/
			upload(obj) {
				console.log("ss");
				this.$refs[obj].submit(); //上传文件
			},
			/* 检查文件是否存在*/
			find_file(sparkEnd) {
				  fileFind({
						file_md5: sparkEnd,
					})
					.then((res) => {
						console.log("检查", res);
						if (res.code == "1") {
							//文件存在，无需上传
							this.filesArr.push(res.data.file_id);
						} else {
							//文件不存在，上传文件
							this.$refs['frontImg'].submit()
						}
					});
			},
			/* 文件上传成功，保存文件*/
			save_file(file_uri) {
				  fileSave({
						file_uri: this.upfileData.key,
					})
					.then((res) => {
						console.log("保存", res);
						if (res.code == "1") {
							this.filesArr.push(res.data.file_id);
						}
					});
			},
			/* 文件上传成功*/
			uploadFiles() {
				this.save_file();
			},
    //下载文件
    downloadFile(){
      orderDownloadFile({admin_token:localStorage.getItem('yhc_admin_token'),
        order_number:this.id}).then(res=>{
        console.log('下载文件内容:',res.data)
      })
    },
    getOrderManageDetail(){
      orderManageDetail({order_id:this.id}).then(res=>{
        console.log('订单详情信息:',res.data)
        if(res.data.code == '1'){
          this.order = this.orderFileList = res.data.data
          this.rows = res.data.data.count
        }
      })
    },
    readInfo(nav){
      this.showInfoModel = true
      this.content = nav
    },
    closeInfoModel(){
      this.showInfoModel = false
    },
    //打开新文件
    updownFile(url){
      window.open(url)
    },
    goBack(){
      this.$router.push('/orderManage')
    },
    formatStepStatus(value){
      console.log(value)
        // "待支付",
				// "待打印",
				// "打印中",
				// "待发货",
				// "已发货",
				// "已取消",
				// "已完成",
      if (value === 1) {
        //待发货
        return 2;
      } else if (value === 2) {
        //已发货
        return 3;
      } else if (value === 3) {
        //已完成
        return 4;
      }else if(value == 4){
        return 5
      }else if(value == 5){
        return 6
      }else if(value == 6){
        return 7
      }else{
         //待付款、已关闭、无限订单
        return 1;
      }
    },
    //订单文件列表
    getOrderManagePrint(){
      orderManagePrint(this.orderFileList).then(res=>{
        console.log('订单文件列表:',res.data)
        if(res.data.code == '1'){
          this.orderFileList = res.data.data
        }
      })
    },
  }
}
</script>
<style>
  .detail-container {
    width: 80%;
    padding: 20px 20px 20px 20px;
    margin: 60px auto;
  }

  .operate-container {
    background: #F2F6FC;
    height: 80px;
    margin: -20px -20px 0;
    line-height: 80px;
  }

  .operate-button-container {
    float: right;
    margin-right: 20px
  }

  .table-layout {
    margin-top: 20px;
    border-left: 1px solid #DCDFE6;
    border-top: 1px solid #DCDFE6;
  }

  .table-cell {
    height: 60px;
    line-height: 40px;
    border-right: 1px solid #DCDFE6;
    border-bottom: 1px solid #DCDFE6;
    padding: 10px;
    font-size: 14px;
    color: #606266;
    text-align: center;
    overflow: hidden;
  }

  .table-cell-title {
    border-right: 1px solid #DCDFE6;
    border-bottom: 1px solid #DCDFE6;
    padding: 10px;
    background: #F2F6FC;
    text-align: center;
    font-size: 14px;
    color: #303133;
  }   

.service-list{
  padding: 16px;
}
.service-list .service-list-item {
    float: left;
    width: 19%;
    margin-right: 16px;
    background: #fff;
    -webkit-transition: all .2s;
    -moz-transition: all .2s;
    transition: all .2s;
}
.service-title{
  background-color: #0f2c14 !important;
  height: 60px;
}
.service-title h3 {
    font-size: 24px;
    text-align: center;
    color: #fff;
    line-height: 60px;
    font-weight: normal;
}

.service-content {
    border: 1px solid #E7E9EC;
    border-top: 0;
}

.service-content-list {
    margin-top: 20px;
}

.service-content-list li {
    position: relative;
    text-align: left;
}
.service-content-list li .p1{
  margin-right: 10px;
}
.service-content-list li h4 p{
  font-weight: normal;
}
.service-content-list li:before {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #C1C5CB;
    content: "";
    position: absolute;
    left: -16px;
    top: 7px;
}

.service-content-list li h4 {
    font-size: 14px;
    color: #616366;
    font-weight: 700;
    margin-bottom: 3px;
    line-height: 20px;
}

.service-content-list li p {
    font-size: 14px;
    color: #333;
}

.service-list-more {
    width: 200px;
    height: 40px;
    display: block;
    margin: 0 auto;
    color: #F96A69;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    border: 1px solid #F96A69;
    transition: all .2s;
}

.service-list-more:hover {
    background: #F96A69;
    color: #fff;
}

.service-list .service-list-item:hover {
    box-shadow: 0 7px 39px rgba(0,0,0,.1)
}
.showFotDom .btn {
    display: block;
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    border: 1px solid #dcdee0;
    color: #323233;
    font-size: 14px;
    margin-right: 16px;
}
#refund .el-dialog {
    width: 440px !important;
    border-radius: 8px !important;
    padding: 24px !important;
}
#refund .el-dialog__header{
  display: none;
}
#refund .el-dialog__footer button {
    width: 260px;
    height: 40px;
    background: #4e9f5b;
    border-radius: 4px;
}
.btn {
    display: block;
    line-height: 30px;
    text-align: center;
    border-radius: 2px;
    -webkit-border-radius: 2px;
    border: 1px solid #dcdee0;
    color: #323233;
    font-size: 14px;
    margin-right: 16px;
}
.btn2 {
    border-color: #4d9859;
    color: #fff;
    background: #4d9859;
}
</style>